import React from "react"
import { Link } from "react-router-dom"

class EventSchedulesOne extends React.Component {
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName("tabs_item")
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none"
    }

    tablinks = document.getElementsByTagName("li")
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "")
    }

    document.getElementById(tabNmae).style.display = "block"
    evt.currentTarget.className += "current"
  }
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      data2: [],
      data3: [],
      loading: true,
      error: null,
    }
  }

  componentDidMount() {
    fetch("https://negeriku-dev.vercel.app/app/api/agenda?date=2023-11-24")
      .then((response) => response.json())
      .then(
        (data) => {
          this.setState({
            data: data.data,
            loading: false,
          })
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          })
        }
      )
    fetch("https://negeriku-dev.vercel.app/app/api/agenda?date=2023-11-25")
      .then((response) => response.json())
      .then(
        (data2) => {
          this.setState({
            data2: data2.data,
            loading: false,
          })
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          })
        }
      )
    fetch("https://negeriku-dev.vercel.app/app/api/agenda?date=2023-11-26")
      .then((response) => response.json())
      .then(
        (data3) => {
          this.setState({
            data3: data3.data,
            loading: false,
          })
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          })
        }
      )
  }
  render() {
    const { data, data2, data3, loading, error } = this.state

    if (loading) {
      return <div>Loading...</div>
    }

    if (error) {
      return <div>Error: {error.message}</div>
    }

    return (
      <section className="schedule-area bg-image ptb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab">
                <ul className="tabs active">
                  <li
                    onClick={(e) => this.openTabSection(e, "tab1")}
                    className="current"
                  >
                    <Link to="#">
                      First Day
                      <span>24 November 2023</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, "tab2")}>
                    <Link to="#">
                      Second Day
                      <span>25 November 2023</span>
                    </Link>
                  </li>

                  <li onClick={(e) => this.openTabSection(e, "tab3")}>
                    <Link to="#">
                      Third Day
                      <span>26 November 2023</span>
                    </Link>
                  </li>
                </ul>

                <div className="tab_content">
                  <div id="tab1" className="tabs_item">
                    <ul className="accordion">
                      {data.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <Link className="accordion-title" to="#">
                            <div className="author author-multi">
                              {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                              {agenda.speakers.map((speaker) => (
                                <img
                                  key={speaker.id}
                                  src={speaker.image}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={speaker.name}
                                  alt="Author"
                                />
                              ))}
                            </div>

                            <div className="schedule-info">
                              <h3>{agenda.title}</h3>
                              <p>{agenda.description}</p>
                              <p>
                                <i className="icofont-wall-clock"></i>{" "}
                                {agenda.start_time} - {agenda.end_time}
                              </p>
                              <ul>
                                <li>
                                  {agenda.speakers.map((speaker, index) => (
                                    <div key={speaker.id}>
                                      <i className="icofont-user-suited"></i> By{" "}
                                      <span>{speaker.name}</span> {speaker.role}{" "}
                                      at {speaker.origin}
                                      {/* {index !== agenda.speakers.length - 1 && (
                                        <br />
                                      )} */}
                                    </div>
                                  ))}
                                </li>
                              </ul>
                            </div>
                          </Link>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div id="tab2" className="tabs_item">
                    <ul className="accordion">
                      {data2.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <Link className="accordion-title" to="#">
                            <div className="author author-multi">
                              {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                              {agenda.speakers.map((speaker) => (
                                <img
                                  key={speaker.id}
                                  src={speaker.image}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={speaker.name}
                                  alt="Author"
                                />
                              ))}
                            </div>

                            <div className="schedule-info">
                              <h3>{agenda.title}</h3>
                              <p>{agenda.description}</p>
                              <p>
                                <i className="icofont-wall-clock"></i>{" "}
                                {agenda.start_time} - {agenda.end_time}
                              </p>
                              <ul>
                                <li>
                                  {agenda.speakers.map((speaker, index) => (
                                    <div key={speaker.id}>
                                      <i className="icofont-user-suited"></i> By{" "}
                                      <span>{speaker.name}</span> {speaker.role}{" "}
                                      at {speaker.origin}
                                      {/* {index !== agenda.speakers.length - 1 && (
                                        <br />
                                      )} */}
                                    </div>
                                  ))}
                                </li>
                              </ul>
                            </div>
                          </Link>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div id="tab3" className="tabs_item">
                    <ul className="accordion">
                      {data3.map((agenda) => (
                        <li key={agenda.id} className="accordion-item">
                          <Link className="accordion-title" to="#">
                            <div className="author author-multi">
                              {/* Assuming there might be multiple speakers, we're just selecting the first one here */}
                              {agenda.speakers.map((speaker) => (
                                <img
                                  key={speaker.id}
                                  src={speaker.image}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={speaker.name}
                                  alt="Author"
                                />
                              ))}
                            </div>

                            <div className="schedule-info">
                              <h3>{agenda.title}</h3>
                              <p>{agenda.description}</p>
                              <p>
                                <i className="icofont-wall-clock"></i>{" "}
                                {agenda.start_time} - {agenda.end_time}
                              </p>
                              <ul>
                                <li>
                                  {agenda.speakers.map((speaker, index) => (
                                    <div key={speaker.id}>
                                      <i className="icofont-user-suited"></i> By{" "}
                                      <span>{speaker.name}</span> {speaker.role}{" "}
                                      at {speaker.origin}
                                      {/* {index !== agenda.speakers.length - 1 && (
                                        <br />
                                      )} */}
                                    </div>
                                  ))}
                                </li>
                              </ul>
                            </div>
                          </Link>

                          <div className="accordion-content">
                            <p>{agenda.description}</p>

                            <div className="row h-100 align-items-center">
                              <div className="col-lg-6 col-md-7">
                                <div className="location">
                                  <b>Location:</b> {agenda.location}
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-5 text-right">
                                <Link to="#" className="btn btn-primary">
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
        </div>
        <div className="shape2 rotateme">
          <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
        </div>
        <div className="shape3 rotateme">
          <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
        </div>
        <div className="shape4">
          <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
        </div>
      </section>
    )
  }
}

export default EventSchedulesOne
