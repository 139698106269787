import React from "react"
import { Link } from "react-router-dom"
import "react-accessible-accordion/dist/fancy-example.css"
import Footer from "../Common/Footer"

class Faq extends React.Component {
  submitHandler = (e) => {
    e.preventDefault()
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>Terms of Services</h1>
            <span>Terms of Services</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Terms of Services</li>
            </ul>
          </div>
        </div>

        <section className="faq-area ptb-120">
          <div className="container">
            <div className="faq-contact">
              <h3>Terms of Services</h3>
              {/* div text align left */}
              <div style={{ textAlign: "left" }}>
                <h1>Terms of Service</h1>
                <p>
                  Thank you for choosing to use our online ticketing website for
                  events. These Terms of Service govern your use of our website.
                  By accessing or using our website, you agree to comply with
                  these terms and conditions. Please read them carefully.
                </p>
                <h2>1. Acceptance of Terms</h2>
                <p>
                  By accessing or using our website, you acknowledge that you
                  have read, understood, and agree to be bound by these Terms of
                  Service. If you do not agree to these terms, you should not
                  access or use our website.
                </p>
                <h2>2. Ticket Purchases</h2>
                <p>
                  When you purchase tickets through our website, you agree to
                  provide accurate and complete information. You are responsible
                  for ensuring the accuracy of the information provided for the
                  ticket purchase. All ticket sales are final, and refunds or
                  exchanges may be subject to specific event policies.
                </p>
                <h2>3. Intellectual Property</h2>
                <p>
                  All intellectual property rights related to our website,
                  including trademarks, logos, graphics, and content, belong to
                  us or our licensors. You may not use, reproduce, or distribute
                  any of the intellectual property without our prior written
                  consent.
                </p>
                <h2>4. Limitation of Liability</h2>
                <p>
                  We strive to provide accurate and reliable information on our
                  website, but we do not guarantee the accuracy, completeness,
                  or timeliness of the content. We are not liable for any
                  direct, indirect, incidental, consequential, or special
                  damages arising from your use of our website or the inability
                  to access or use it.
                </p>
                <h2>5. Third-Party Links</h2>
                <p>
                  Our website may contain links to third-party websites or
                  services that are not owned or controlled by us. We are not
                  responsible for the content, privacy policies, or practices of
                  these third parties. Your interactions with any third-party
                  website or service are solely between you and that third
                  party.
                </p>
                <h2>6. Modifications</h2>
                <p>
                  We reserve the right to modify or update these Terms of
                  Service at any time without prior notice. It is your
                  responsibility to review these terms periodically for any
                  changes. By continuing to access or use our website after the
                  modifications, you agree to be bound by the revised terms.
                </p>
                <h2>7. Governing Law</h2>
                <p>
                  These Terms of Service are governed by and construed in
                  accordance with the laws of [your jurisdiction]. Any disputes
                  arising from these terms will be subject to the exclusive
                  jurisdiction of the courts in [your jurisdiction].
                </p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    )
  }
}

export default Faq
