import React from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import Footer from "../Common/Footer"

class BlogOne extends React.Component {
  state = {
    posts: [],
    currentPage: 1,
    totalPages: 1,
  }

  componentDidMount() {
    this.fetchBlogPosts(this.state.currentPage)
  }

  async fetchBlogPosts(page) {
    try {
      const response = await axios.get(
        `https://negeriku-dev.vercel.app/app/api/news?page=${page}`
        // `http://127.0.0.1:8000/api/news?page=${page}`
      )
      const { data, current_page, last_page } = response.data.data

      this.setState({
        posts: data,
        currentPage: current_page,
        totalPages: last_page,
      })
    } catch (error) {
      console.error("Error fetching blog posts:", error)
    }
  }

  render() {
    const { posts, currentPage, totalPages } = this.state
    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>News</h1>
            <span>Our Latest News</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>News</li>
            </ul>
          </div>
        </div>

        <section className="blog-area ptb-120 bg-image">
          <div className="container">
            <div className="row">
              {posts.map((post) => (
                <div className="col-lg-4 col-md-6" key={post.id}>
                  <div className="single-blog-post">
                    <div className="blog-image">
                      <Link to={`news/${post.slug}`}>
                        <img src={post.image} alt={post.title} />
                      </Link>

                      <div className="post-tag">
                        <Link to={`news/${post.slug}`}>
                          {post.category.charAt(0).toUpperCase() +
                            post.category.slice(1)}
                        </Link>
                      </div>
                    </div>

                    <div className="blog-post-content">
                      <span className="date">
                        {new Date(post.created_at).toLocaleDateString("id-ID")}
                      </span>
                      <h3>
                        <Link to={`news/${post.slug}`}>{post.title}</Link>
                      </h3>
                      {/* <p>{post.content.slice(0, 300)}...</p> */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.content.slice(0, 300),
                        }}
                      />
                      <Link to={`news/${post.slug}`} className="read-more-btn">
                        Read More <i className="icofont-double-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-lg-12 col-md-12">
                <div className="pagination-area">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={() => this.fetchBlogPosts(currentPage - 1)}
                        >
                          <i className="icofont-double-left"></i>
                        </Link>
                      </li>
                      {Array.from({ length: totalPages }, (_, index) => (
                        <li
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                          key={index}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            onClick={() => this.fetchBlogPosts(index + 1)}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={() => this.fetchBlogPosts(currentPage + 1)}
                        >
                          <i className="icofont-double-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    )
  }
}

export default BlogOne
