import React from "react"
import { Link } from "react-router-dom"
import lax from "lax.js"
import OwlCarousel from "react-owl-carousel3"
import PlatinumSponsors from "../../LaxButton/PlatinumSponsors"
import GoldSponsors from "../../LaxButton/GoldSponsors"
import Footer from "../../Common/Footer"

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 5,
    },
  },
}

class Sponsor extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>Exhibitors</h1>
            <span>Check Who Makes This Event Possible!</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Exhibitors</li>
            </ul>
          </div>
        </div>

        <section className="partner-area ptb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="partner-title platinum-sponsor">
                  <PlatinumSponsors />
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <Link to="#" target="_blank">
                    <img
                      style={{ maxWidth: "300px", maxHeight: "100px" }}
                      src={require("../../../assets/images/sponsors/KV-03.png")}
                      alt="Partner Logo"
                    />
                    <img
                      style={{ maxWidth: "300px", maxHeight: "100px" }}
                      src={require("../../../assets/images/sponsors/KV-03.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="border"></div>
              </div>

              <div className="col-lg-12">
                <div className="partner-title  platinum-sponsor">
                  <GoldSponsors />
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="partner-item">
                  <Link to="#" target="_blank">
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={require("../../../assets/images/sponsors/kupu.png")}
                      alt="Partner Logo"
                    />
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={require("../../../assets/images/sponsors/kupu.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="border"></div>
              </div>
              <div className="col-lg-12">
                <div className="partner-title  platinum-sponsor">
                  <h3 className="lax" data-lax-preset="driftLeft">
                    Supported By
                  </h3>
                </div>
              </div>

              <OwlCarousel
                className="gold-partner-slides owl-carousel owl-theme"
                {...options}
              >
                <div className="col-lg-12 col-md-12">
                  <div className="partner-item">
                    <Link to="#" target="_blank">
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/embassy.jpg")}
                        alt="Partner Logo"
                      />
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/embassy.jpg")}
                        alt="Partner Logo"
                      />
                    </Link>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="partner-item">
                    <Link to="#" target="_blank">
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/KV-08.png")}
                        alt="Partner Logo"
                      />
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/KV-08.png")}
                        alt="Partner Logo"
                      />
                    </Link>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="partner-item">
                    <Link to="#" target="_blank">
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/KV-09.png")}
                        alt="Partner Logo"
                      />
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/KV-09.png")}
                        alt="Partner Logo"
                      />
                    </Link>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="partner-item">
                    <Link to="#" target="_blank">
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/KV-04.png")}
                        alt="Partner Logo"
                      />
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/KV-04.png")}
                        alt="Partner Logo"
                      />
                    </Link>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="partner-item">
                    <Link to="#" target="_blank">
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/green-climate.png")}
                        alt="Partner Logo"
                      />
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/green-climate.png")}
                        alt="Partner Logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="partner-item">
                    <Link to="#" target="_blank">
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/KV-05.png")}
                        alt="Partner Logo"
                      />
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={require("../../../assets/images/sponsors/KV-05.png")}
                        alt="Partner Logo"
                      />
                    </Link>
                  </div>
                </div>
              </OwlCarousel>

              <div className="col-lg-12">
                <div className="border"></div>
              </div>
              <div className="col-lg-12">
                <div className="partner-title  platinum-sponsor">
                  <h3 className="lax" data-lax-preset="driftLeft">
                    Official Partner
                  </h3>
                </div>
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="partner-item">
                  <Link to="#" target="_blank">
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={require("../../../assets/images/sponsors/atourin.png")}
                      alt="Partner Logo"
                    />
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={require("../../../assets/images/sponsors/atourin.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="partner-item">
                  <Link to="#" target="_blank">
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={require("../../../assets/images/sponsors/logo PANDI.png")}
                      alt="Partner Logo"
                    />
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={require("../../../assets/images/sponsors/logo PANDI.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="partner-item">
                  <Link to="#" target="_blank">
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={require("../../../assets/images/sponsors/goorita.png")}
                      alt="Partner Logo"
                    />
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={require("../../../assets/images/sponsors/goorita.png")}
                      alt="Partner Logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <section className="cta-area">
                <div className="container">
                  <div className="row h-100 align-items-center">
                    <div className="col-lg-8">
                      <h3>Become a Sponsor</h3>
                      <span>
                        Join us in making this event truly memorable! Your
                        support helps us provide a fantastic experience for all
                        attendees. As a sponsor, you'll also benefit from
                        exposure to a diverse and engaged audience.
                      </span>
                    </div>

                    <div className="col-lg-4 text-right">
                      <Link to="#" className="btn btn-secondary">
                        Become a Sponsor
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Sponsor
