import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import lax from "lax.js"
import OwlCarousel from "react-owl-carousel3"
import PlatinumSponsors from "../LaxButton/PlatinumSponsors"
import GoldSponsors from "../LaxButton/GoldSponsors"
import Footer from "../Common/Footer"

const options = {
  loop: true,
  nav: false,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 5,
    },
  },
}

function Sponsor() {
  const [sponsors, setSponsors] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    lax.setup()
    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    const fetchSponsors = async () => {
      try {
        const response = await axios.get(
          "https://negeriku.webapps.id/api/sponsor?limit=100"
        )
        const sponsorsData = response.data.data.data

        const categorizedSponsors = sponsorsData.reduce((acc, sponsor) => {
          if (!acc[sponsor.type]) {
            acc[sponsor.type] = []
          }
          acc[sponsor.type].push(sponsor)
          return acc
        }, {})

        setSponsors(categorizedSponsors)
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching sponsors", error)
        setIsLoading(false)
      }
    }

    fetchSponsors()
  }, [])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <React.Fragment>
      <div className="page-title-area item-bg2">
        <div className="container">
          <h1>Sponsors</h1>
          <span>Check Who Makes This Event Possible!</span>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>Sponsors</li>
          </ul>
        </div>
      </div>

      <section className="partner-area ptb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="partner-title platinum-sponsor">
                <PlatinumSponsors />
              </div>
            </div>
            {sponsors["organized"].map((sponsor) => (
              <div key={sponsor.id} className="col-lg-12 col-md-12 mx-auto">
                <div className="partner-item">
                  <a
                    href={sponsor.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={sponsor.image}
                      alt={`${sponsor.name} Logo`}
                    />
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={sponsor.image}
                      alt={`${sponsor.name} Logo`}
                    />
                  </a>
                </div>
              </div>
            ))}
            {/* <div className="col-lg-12 col-md-12">
            <div className="partner-item">
              <Link to="#">
                <img
                  style={{ maxWidth: "300px", maxHeight: "100px" }}
                  src={require("../../assets/images/sponsors/KV-03.png")}
                  alt="Partner Logo"
                />
                <img
                  style={{ maxWidth: "300px", maxHeight: "100px" }}
                  src={require("../../assets/images/sponsors/KV-03.png")}
                  alt="Partner Logo"
                />
              </Link>
            </div>
          </div> */}

            <div className="col-lg-12">
              <div className="border"></div>
            </div>

            <div className="col-lg-12">
              <div className="partner-title  platinum-sponsor">
                <GoldSponsors />
              </div>
            </div>
            {sponsors["co-organized"].map((sponsor) => (
              <div key={sponsor.id} className="col-lg-12 col-md-12 mx-auto">
                <div className="partner-item">
                  <a
                    href={sponsor.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={sponsor.image}
                      alt={`${sponsor.name} Logo`}
                    />
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={sponsor.image}
                      alt={`${sponsor.name} Logo`}
                    />
                  </a>
                </div>
              </div>
            ))}
            {/* <div className="col-lg-12 col-md-12">
            <div className="partner-item">
              <Link to="#">
                <img
                  style={{ maxWidth: "259px", maxHeight: "87px" }}
                  src={require("../../assets/images/sponsors/kupu.png")}
                  alt="Partner Logo"
                />
                <img
                  style={{ maxWidth: "259px", maxHeight: "87px" }}
                  src={require("../../assets/images/sponsors/kupu.png")}
                  alt="Partner Logo"
                />
              </Link>
            </div>
          </div> */}

            <div className="col-lg-12">
              <div className="border"></div>
            </div>
            <div className="col-lg-12">
              <div className="partner-title  platinum-sponsor">
                <h3 className="lax" data-lax-preset="driftLeft">
                  Supported By
                </h3>
              </div>
            </div>

            <OwlCarousel
              className="gold-partner-slides owl-carousel owl-theme"
              {...options}
            >
              {sponsors["supported"].map((sponsor) => (
                <div key={sponsor.id} className="col-lg-3 col-md-3 mx-auto">
                  <div className="partner-item">
                    <a
                      href={sponsor.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={sponsor.image}
                        alt={`${sponsor.name} Logo`}
                      />
                      <img
                        style={{ maxWidth: "259px", maxHeight: "87px" }}
                        src={sponsor.image}
                        alt={`${sponsor.name} Logo`}
                      />
                    </a>
                  </div>
                </div>
              ))}
              {/* <div className="col-lg-12 col-md-12">
              <div className="partner-item">
                <Link to="#">
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/embassy.jpg")}
                    alt="Partner Logo"
                  />
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/embassy.jpg")}
                    alt="Partner Logo"
                  />
                </Link>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="partner-item">
                <Link to="#">
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/KV-08.png")}
                    alt="Partner Logo"
                  />
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/KV-08.png")}
                    alt="Partner Logo"
                  />
                </Link>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="partner-item">
                <Link to="#">
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/KV-09.png")}
                    alt="Partner Logo"
                  />
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/KV-09.png")}
                    alt="Partner Logo"
                  />
                </Link>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="partner-item">
                <Link to="#">
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/KV-04.png")}
                    alt="Partner Logo"
                  />
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/KV-04.png")}
                    alt="Partner Logo"
                  />
                </Link>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="partner-item">
                <Link to="#">
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/green-climate.png")}
                    alt="Partner Logo"
                  />
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/green-climate.png")}
                    alt="Partner Logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="partner-item">
                <Link to="#">
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/KV-05.png")}
                    alt="Partner Logo"
                  />
                  <img
                    style={{ maxWidth: "259px", maxHeight: "87px" }}
                    src={require("../../assets/images/sponsors/KV-05.png")}
                    alt="Partner Logo"
                  />
                </Link>
              </div>
            </div> */}
            </OwlCarousel>

            <div className="col-lg-12">
              <div className="border"></div>
            </div>
            <div className="col-lg-12">
              <div className="partner-title  platinum-sponsor">
                <h3 className="lax" data-lax-preset="driftLeft">
                  Sponsored By
                </h3>
              </div>
            </div>
            {sponsors["sponsored"].map((sponsor) => (
              <div key={sponsor.id} className="col-lg-4 col-md-4 mx-auto">
                <div className="partner-item">
                  <a
                    href={sponsor.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={sponsor.image}
                      alt={`${sponsor.name} Logo`}
                    />
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={sponsor.image}
                      alt={`${sponsor.name} Logo`}
                    />
                  </a>
                </div>
              </div>
            ))}
            <div className="col-lg-12">
              <div className="border"></div>
            </div>
            <div className="col-lg-12">
              <div className="partner-title  platinum-sponsor">
                <h3 className="lax" data-lax-preset="driftLeft">
                  Official Partner
                </h3>
              </div>
            </div>
            {sponsors["partner"].map((sponsor) => (
              <div key={sponsor.id} className="col-lg-3 col-md-3 mx-auto">
                <div className="partner-item">
                  <a
                    href={sponsor.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={sponsor.image}
                      alt={`${sponsor.name} Logo`}
                    />
                    <img
                      style={{ maxWidth: "259px", maxHeight: "87px" }}
                      src={sponsor.image}
                      alt={`${sponsor.name} Logo`}
                    />
                  </a>
                </div>
              </div>
            ))}
            {/* <div className="col-lg-4 col-md-4">
            <div className="partner-item">
              <Link to="#">
                <img
                  style={{ maxWidth: "259px", maxHeight: "87px" }}
                  src={require("../../assets/images/sponsors/atourin.png")}
                  alt="Partner Logo"
                />
                <img
                  style={{ maxWidth: "259px", maxHeight: "87px" }}
                  src={require("../../assets/images/sponsors/atourin.png")}
                  alt="Partner Logo"
                />
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-4">
            <div className="partner-item">
              <Link to="#">
                <img
                  style={{ maxWidth: "259px", maxHeight: "87px" }}
                  src={require("../../assets/images/sponsors/logo PANDI.png")}
                  alt="Partner Logo"
                />
                <img
                  style={{ maxWidth: "259px", maxHeight: "87px" }}
                  src={require("../../assets/images/sponsors/logo PANDI.png")}
                  alt="Partner Logo"
                />
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-4">
            <div className="partner-item">
              <Link to="#">
                <img
                  style={{ maxWidth: "259px", maxHeight: "87px" }}
                  src={require("../../assets/images/sponsors/goorita.png")}
                  alt="Partner Logo"
                />
                <img
                  style={{ maxWidth: "259px", maxHeight: "87px" }}
                  src={require("../../assets/images/sponsors/goorita.png")}
                  alt="Partner Logo"
                />
              </Link>
            </div>
          </div> */}

            <div className="col-lg-12">
              <div className="border"></div>
            </div>
            {sponsors["exhibitor"] && (
              <>
                <div className="col-lg-12">
                  <div className="partner-title  platinum-sponsor">
                    <h3 className="lax" data-lax-preset="driftLeft">
                      Association Partner
                    </h3>
                  </div>
                </div>
                {sponsors["association"].map((sponsor) => (
                  <div key={sponsor.id} className="col-lg-4 col-md-4 mx-auto">
                    <div className="partner-item">
                      <a
                        href={sponsor.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          style={{ maxWidth: "259px", maxHeight: "87px" }}
                          src={sponsor.image}
                          alt={`${sponsor.name} Logo`}
                        />
                        <img
                          style={{ maxWidth: "259px", maxHeight: "87px" }}
                          src={sponsor.image}
                          alt={`${sponsor.name} Logo`}
                        />
                      </a>
                    </div>
                  </div>
                ))}
                <div className="col-lg-12">
                  <div className="border"></div>
                </div>
              </>
            )}
            {sponsors["exhibitor"] && (
              <>
                <div className="col-lg-12">
                  <div className="partner-title  platinum-sponsor">
                    <h3 className="lax" data-lax-preset="driftLeft">
                      Exhibitor
                    </h3>
                  </div>
                </div>

                <OwlCarousel
                  className="gold-partner-slides owl-carousel owl-theme"
                  {...options}
                >
                  {sponsors["exhibitor"].map((sponsor) => (
                    <div key={sponsor.id} className="col-lg-4 col-md-4 mx-auto">
                      <div className="partner-item ">
                        <a
                          href={sponsor.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{
                              maxWidth: "259px",
                              maxHeight: "87px",
                              display: "block",
                              margin: "auto",
                            }}
                            src={sponsor.image}
                            alt={`${sponsor.name} Logo`}
                          />
                          <img
                            style={{
                              maxWidth: "259px",
                              maxHeight: "87px",
                              display: "block",
                              margin: "auto",
                            }}
                            src={sponsor.image}
                            alt={`${sponsor.name} Logo`}
                          />
                        </a>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
                <div className="col-lg-12">
                  <div className="border"></div>
                </div>
              </>
            )}
          </div>
          <div className="row mt-5">
            <section className="cta-area">
              <div className="container">
                <div className="row h-100 align-items-center">
                  <div className="col-lg-8">
                    <h3>Become a Sponsor</h3>
                    <span>
                      Join us in making this event truly memorable! Your support
                      helps us provide a fantastic experience for all attendees.
                      As a sponsor, you'll also benefit from exposure to a
                      diverse and engaged audience.
                    </span>
                  </div>

                  <div className="col-lg-4 text-right">
                    <Link
                      to="sponsors-application"
                      className="btn btn-secondary"
                    >
                      Become a Sponsor
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  )
}

export default Sponsor
