import React from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import Footer from "../Common/Footer"

class BlogTwo extends React.Component {
  state = {
    galleryData: [], // Initialize an empty array to store the fetched data
    currentPage: 1,
    totalPages: 1,
  }
  componentDidMount() {
    this.fetchGallery(this.state.currentPage)
  }

  async fetchGallery(page) {
    try {
      const response = await axios.get(
        `https://negeriku-dev.vercel.app/app/api/gallery?page=${page}`
      )
      const { data, current_page, last_page } = response.data.data

      this.setState({
        galleryData: data,
        currentPage: current_page,
        totalPages: last_page,
      })
    } catch (error) {
      console.error("Error fetching gallery:", error)
    }
  }

  render() {
    const { galleryData, currentPage, totalPages } = this.state
    return (
      <React.Fragment>
        <div className="page-title-area item-bg3">
          <div className="container">
            <h1>Gallery</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Gallery</li>
            </ul>
          </div>
        </div>

        <section className="blog-area ptb-120 bg-image">
          <div className="container">
            <div className="row">
              {galleryData.map((item) => {
                return (
                  <div className="col-lg-6 col-md-6" key={item.id}>
                    <div className="single-blog-card">
                      <Link to={`/gallery/${item.id}`}>
                        {item.type === "image" ? (
                          <div
                            style={{
                              position: "relative",
                              paddingBottom: "56.25%" /* 16:9 Aspect Ratio */,
                              height: 0,
                            }}
                          >
                            <img
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                border: 0,
                              }}
                              src={item.content}
                              alt={item.title}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              position: "relative",
                              paddingBottom: "56.25%" /* 16:9 Aspect Ratio */,
                              height: 0,
                            }}
                          >
                            <iframe
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                border: 0,
                              }}
                              src={`https://www.youtube.com/embed/${item.content}?autoplay=1&mute=1`}
                              title={item.title}
                              frameBorder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                            />
                          </div>
                        )}
                      </Link>

                      <div className="blog-post-content">
                        <h3>
                          <Link to={`/gallery/${item.id}`}>{item.title}</Link>
                        </h3>
                        {/* <p>{item.description}</p> */}
                        <p>{item.description.slice(0, 200)}...</p>
                      </div>
                    </div>
                  </div>
                )
              })}

              <div className="col-lg-12 col-md-12">
                <div className="pagination-area">
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={() => this.fetchGallery(currentPage - 1)}
                        >
                          <i className="icofont-double-left"></i>
                        </Link>
                      </li>
                      {Array.from({ length: totalPages }, (_, index) => (
                        <li
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                          key={index}
                        >
                          <Link
                            className="page-link"
                            to="#"
                            onClick={() => this.fetchGallery(index + 1)}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <Link
                          className="page-link"
                          to="#"
                          onClick={() => this.fetchGallery(currentPage + 1)}
                        >
                          <i className="icofont-double-right"></i>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    )
  }
}

export default BlogTwo
