import React from "react"
import lax from "lax.js"
import { Link } from "react-router-dom"
import LaxButton from "../Shared/LaxButton"

class About extends React.Component {
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }

  render() {
    return (
      <section className="about-area ptb-120 bg-image">
        <div className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6">
              <div className="about-content">
                <span>Join The Event</span>
                <h2>DISCOVERING THE MAGNIFICENCE OF INDONESIA EXPO</h2>
                <p>
                  Currently many tourists want to travel like a locals and to
                  immerse themselves in the culture, traditions and language of
                  a destination. These people grow tired of resorts and standard
                  holidays, there has been a shift towards wanting to see the
                  ‘real’ side of the destinations they visit. Lately many
                  tourist concern about the sustainability of the trips people
                  take. This has resulted in tourists wanting to bring welfare
                  to a local community without harming it. Europeans also tend
                  to spend a lot of money: on average about € 2,000 for their
                  summer holiday. Last but be not least, they have The
                  willinghess to enjoy homestays, support local businesses and
                  The local population. These are the market research result
                  quoted by Centre for the Promotion of Imports from developing
                  countries.
                </p>
                <p>
                  Village Tourism is Green Tourism and also Ecotourism can be
                  regarded as a specialized area within the category of nature
                  tourism. Its focus is on learning about the environment,
                  minimizing negative impacts and contributing (financially or
                  otherwise) to the long-term protection of a community or
                  natural site. Demand for ecotourism is increasing amongst
                  Europeans. The COVID-19 pandemic has given ecotourism an
                  additional boost.
                </p>
                <p>
                  In recent years, the demand for ecotourism has been growing
                  rapidly. The ecotourism sector is considered the
                  fastest-growing segment within the global tourism industry.
                  Since the early 1990s, the segment has been growing by 20%-34%
                  per year, whilst the overall tourism industry has been growing
                  by only 4.3% per year. The demand for ecotourism is high and
                  will continue to grow. For several years, tourists have become
                  more aware of the need to leave a positive impact on the
                  destinations that they visit. In a 2018 Booking.com survey,
                  87% of all global travellers reported wanting to travel
                  sustainably.
                </p>
                <p>
                  Therefore, NEGERIKU created this event to promote tourism
                  villages in Indonesia which will be attended by community
                  leaders, regional cultural actors, tour operators, and
                  regional representatives so as to provide an overview of
                  tourist villages to potential tourists. This event is also
                  expected to increase foreign tourist visits to Indonesia.
                </p>

                {/* <div className="signature">
                  <img
                    src={require("../../assets/images/signature.png")}
                    alt="signature"
                  />
                </div> */}

                {/* <Link to="/about-3" className="btn btn-primary">
                  Read More
                  <i className="icofont-double-right"></i>
                </Link>

                <Link to="#" className="btn btn-secondary">
                  Buy Ticket
                </Link> */}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="about-image">
                <img
                  src={require("../../assets/images/about1.jpg")}
                  className="about-img1"
                  alt="about"
                />

                <img
                  src={require("../../assets/images/about2.jpg")}
                  className="about-img2"
                  alt="about"
                />
                {/* 
                <img
                  src={require("../../assets/images/shapes/5.png")}
                  className="shape-img"
                  alt="about"
                /> */}

                {/* <LaxButton buttonText="Explore More About" /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About
