import React from "react"
import { Link } from "react-router-dom"
import OwlCarousel from "react-owl-carousel3"
import lax from "lax.js"
import LaxDiv from "../Shared/LaxDiv"
import axios from "axios"

const options = {
  loop: false,
  nav: false,
  dots: true,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
}

class LatestNews extends React.Component {
  state = {
    posts: [],
    currentPage: 1,
    totalPages: 1,
  }

  componentDidMount() {
    this.fetchBlogPosts(this.state.currentPage)
  }

  async fetchBlogPosts(page) {
    try {
      const response = await axios.get(
        `https://negeriku-dev.vercel.app/app/api/news?page=1?limit=7`
      )
      const { data, current_page, last_page } = response.data.data

      this.setState({
        posts: data,
        currentPage: current_page,
        totalPages: last_page,
      })
    } catch (error) {
      console.error("Error fetching blog posts:", error)
    }
  }
  constructor(props) {
    super(props)
    lax.setup()

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY)
      },
      false
    )

    lax.update(window.scrollY)
  }
  render() {
    const { posts } = this.state
    return (
      <section className="blog-area ptb-120 bg-image">
        <div className="container">
          <div className="section-title">
            <span>Info Update!</span>
            <h2>
              Our Latest <b>News</b>
            </h2>

            <LaxDiv text="News" dataPreset="driftLeft" />

            <Link to="news" className="btn btn-primary">
              View All News
            </Link>

            <div className="bar"></div>
          </div>

          <div className="row">
            <OwlCarousel
              className="blog-slides owl-carousel owl-theme"
              {...options}
            >
              {posts.map((post) => (
                <div className="col-lg-12 col-md-12">
                  <div className="single-blog-post">
                    <div className="blog-image">
                      <Link to={`news/${post.slug}`}>
                        <img src={post.image} alt={post.title} />
                      </Link>

                      <div className="post-tag">
                        <Link to={`news/${post.slug}`}>
                          {post.category.charAt(0).toUpperCase() +
                            post.category.slice(1)}
                        </Link>
                      </div>
                    </div>

                    <div className="blog-post-content">
                      <span className="date">
                        {new Date(post.created_at).toLocaleDateString("id-ID")}
                      </span>
                      <h3>
                        <Link to={`news/${post.slug}`}>{post.title}</Link>
                      </h3>
                      {/* <p>{post.content.slice(0, 300)}...</p> */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.content.slice(0, 300),
                        }}
                      />

                      <Link to="#" className="read-more-btn">
                        Read More <i className="icofont-double-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}{" "}
            </OwlCarousel>
          </div>
        </div>
      </section>
    )
  }
}

export default LatestNews
