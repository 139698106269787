import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Footer from "../Common/Footer"

class BlogDetails extends React.Component {
  state = {
    newsArticle: null,
  }

  componentDidMount() {
    const { slug } = this.props.match.params

    // Replace 'YOUR_API_URL_HERE' with the actual URL of your API
    fetch(`https://negeriku-dev.vercel.app/app/api/news?slug=${slug}`)
      // fetch(`http://127.0.0.1:8000/api/news?slug=${slug}`)
      .then((response) => response.json())
      .then((data) => this.setState({ newsArticle: data }))
      .catch((error) => console.error("Error fetching news article:", error))
  }
  render() {
    const { newsArticle } = this.state

    if (!newsArticle) {
      return <div>Loading...</div>
    }
    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>
              {newsArticle.data.title}
              {/* {JSON.stringify(newsArticle)} */}
            </h1>
            {/* <span>Our Latest News</span> */}
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>News</li>
            </ul>
          </div>
        </div>

        <section className="blog-details-area ptb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-details">
                  <div className="post-image">
                    <img src={newsArticle.data.image} alt="blog" width="100%" />
                  </div>
                  <h3>{newsArticle.data.title}</h3>
                  {/* {newsArticle.data.content
                    .split("\n")
                    .map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: newsArticle.data.content,
                    }}
                  />
                </div>

                <div className="post-tag-media">
                  <div className="row h-100 align-items-center">
                    <div className="col-lg-2">
                      <ul className="social-share">
                        <li>
                          <span>Share on:</span>
                        </li>
                        <li>
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                          >
                            <i className="icofont-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                          >
                            <i className="icofont-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://www.instagram.com/?url=${window.location.href}`}
                          >
                            <i className="icofont-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://www.linkedin.com/shareArticle?url=${window.location.href}`}
                          >
                            <i className="icofont-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    )
  }
}

export default BlogDetails
