import React from "react"
import { Link } from "react-router-dom"

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Venue Location</h3>
                <span>
                  <i className="icofont-calendar"></i> 24<sup>th</sup>
                  -26<sup>th</sup> November 2023
                </span>

                <p className="location">
                  <i className="icofont-google-map"></i> Passenger Terminal
                  Amsterdam
                </p>

                <Link to="#" className="contact-authority">
                  <i className="icofont-phone"></i> Contact Our Authority
                </Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="single-footer-widget">
                <h3>Social Connection</h3>
                <p>
                  Don't miss Link thing! Receive daily news You should connect
                  social area for Any Proper Updates Anytime.
                </p>

                <ul className="social-links">
                  <li>
                    <Link to="#" className="facebook" target="_blank">
                      <i className="icofont-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="twitter" target="_blank">
                      <i className="icofont-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="linkedin" target="_blank">
                      <i className="icofont-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="instagram" target="_blank">
                      <i className="icofont-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="copyright-area">
                {/* <div className="logo">
                  <Link to="/">
                    <img
                      src={require("../../assets/images/logo.png")}
                      alt="logo"
                      style={{
                        boxShadow: "0px 0px 10px 5px rgba(255, 255, 255, 0.7)", // white shadow
                        backgroundColor: "white", // white background
                        maxWidth: "259px",
                        maxHeight: "87px",
                      }}
                    />
                  </Link>
                </div> */}
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-services">Terms of Services</Link>
                  </li>
                  <li>
                    <Link to="/gdpr-compliance">GDPR Compliance</Link>
                  </li>
                </ul>
                <p>
                  Copyright <i className="icofont-copyright"></i> 2023 Negeriku.
                  All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
